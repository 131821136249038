<template>
  <div flat class="overflow-hidden">
    <v-app-bar elevation="1" absolute color="white" dark scroll-target="#scrolling-techniques-8">
      <v-toolbar-title class="black--text">Notas de credito </v-toolbar-title>
      <v-btn small color="primary" class="ml-2" @click="GetEditar({ id: 0 })"> Nuevo </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-8" class="mx-0 px-0 overflow-y-auto" max-height="calc(100vh - 76px)">
      <div style="margin-top: 70px">
        <v-card-text>
          <v-row class="mb-1">
            <v-col>
              <statistics-card-vertical
                @eventoClick="filtrarPorEstado(29)"
                :change="''"
                color="secondary"
                :icon="icons.mdiMagnify"
                :statistics="metadata.por_recibir ? metadata.por_recibir : 0"
                stat-title="Por entregar "
              >
              </statistics-card-vertical>
            </v-col>
            <v-col>
              <statistics-card-vertical
                @eventoClick="filtrarPorEstado(33)"
                :change="''"
                color="secondary"
                :icon="icons.mdiMagnify"
                :statistics="metadata.por_validar ? metadata.por_validar : 0"
                stat-title="Por validar"
              >
              </statistics-card-vertical>
            </v-col>
            <v-col>
              <statistics-card-vertical
                :change="''"
                color="secondary"
                :icon="icons.mdiMagnify"
                :statistics="metadata.por_pagar ? metadata.por_pagar : 0"
                stat-title="Por Pagar"
              >
              </statistics-card-vertical>
            </v-col>
            <v-col>
              <statistics-card-vertical
                :change="''"
                color="secondary"
                :icon="icons.mdiMagnify"
                :statistics="metadata.total_ult_compras ? metadata.total_ult_compras : 0"
                stat-title="NC Ultimos 7 dias"
              >
              </statistics-card-vertical>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="cols">
                <v-text-field
                  v-model="filtro.valor"
                  label="numero/Nota Credito/control/documento"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <SelectGrupoNegocio
                  v-model="filtro.id_grupo_negocio"
                  :add="false"
                  :dense="true"
                  :outlined="true"
                  :clearable="true"
                  :ind_principal="true"
                  :storePuesto="true"
                />
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="show = !show">
                  <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-expand-transition>
                  <div v-show="show">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <select-puesto
                          v-model="filtro.id_puesto"
                          :add="false"
                          :dense="true"
                          :outlined="true"
                          :clearable="true"
                          :ind_principal="true"
                          :storePuesto="true"
                          :filtros="{ id_grupo_negocio: filtro.id_grupo_negocio, modulos: '11' }"
                        ></select-puesto>
                      </v-col>
                      <v-col cols="12" md="4">
                        <SelectAlmacen
                          v-model="filtro.almacen"
                          :add="false"
                          :dense="true"
                          :outlined="true"
                          :clearable="true"
                          :filtro="{ id_puesto: filtro.id_puesto }"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <BuscarProveedor
                          :label="`Proveedor`"
                          :add="false"
                          class="mb-4"
                          @GetProveedor="GetdatosProveedor"
                        />
                      </v-col>

                      <v-col cols="12" md="3">
                        <BuscarEmpleado @Getdatos="GetdatosEmpleado" :label="`Empleado`" />
                      </v-col>
                      <v-col cols="12" md="3">
                        <SelectEstado
                          v-model="filtro.estado"
                          :dense="true"
                          :outlined="true"
                          :add="false"
                          :padre="10"
                          :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <DatePicker
                          :clearable="true"
                          v-model="filtro.fecha_factura"
                          :dense="true"
                          :outlined="true"
                          :label="'Fecha Nota Credito'"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <DatePicker
                          :clearable="true"
                          v-model="filtro.fecha_creacion"
                          :dense="true"
                          :outlined="true"
                          :label="'Fecha Creacion'"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn small @click="limpiarFiltros()" :loading="cargandoDatos" class="secondary mr-2"> Limpiar </v-btn>
                <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">ID</th>
                    <th class="text-left">Numeros</th>
                    <th class="text-left">Fechas</th>
                    <th class="text-left">Proveedor</th>
                    <th class="text-left">Puesto</th>
                    <th class="text-left">Empleado</th>
                    <th class="text-right" >Total</th>
                    <th class="text-left">Accion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in Lista" :key="index">
                    <td class="text-center">
                      {{ item.id }} <br />
                      <v-chip x-small :color="colorEstatus(item.estado_json.id)">{{
                        item.estado_json.descripcion
                      }}</v-chip>
                      <br />
                      <v-chip x-small :color="colorEstatusPago(item.estado_pago_json.id)">{{
                        item.estado_pago_json.descripcion
                      }}</v-chip>
                    </td>
                    <td>
                      <small>Documento: {{ item.numero_documento }}</small> <br />
                      <small>Nota Credito: {{ item.numerofactura }}</small
                      ><br />
                      <small>Control: {{ item.numerocontrol }}</small>
                    </td>
                    <td>
                      <small>Nota Credito: {{ funciones.formatoFecha(item.fecha_factura) }}</small> <br />
                      <small>Creacion: {{ funciones.formatoFecha(item.fecha, 3) }}</small>
                    </td>
                    <td>
                      <small> {{ item.proveedor.nombre }} </small> <br />
                      <small> {{ item.proveedor.documento }} </small>
                    </td>
                    <td>
                      <small> {{ item.puesto.nombre }}</small> <br />
                      <small>{{ item.almacen.descripcion }}</small>
                    </td>
                    <td>
                      <small>
                        <v-icon small color="info"> {{ icons.mdiContentSave }}</v-icon>
                        {{ item.empleado_json.nombre }} {{ item.empleado_json.apellido }}</small
                      >
                      <br />
                      <small v-if="item.empleado_valida_json.nombre"
                        ><v-icon small color="success"> {{ icons.mdiAccountCheck }}</v-icon>
                        {{ item.empleado_valida_json.nombre }} {{ item.empleado_valida_json.apellido }}</small
                      >
                      <br />
                      <small v-if="item.empleado_anula_json.nombre">
                        <v-icon small color="error"> {{ icons.mdiCancel }}</v-icon>
                        {{ item.empleado_anula_json.nombre }} {{ item.empleado_anula_json.apellido }}</small
                      >
                    </td>
                    <td class="text-right">
                      $ {{ item.total.toFixed(4) }} <br />
                      Bs {{ (item.total * item.tasa_pais).toFixed(4) }}
                    </td>

                    <td>
                      <BtnPdfCompra :id="item.id" :tipoBtn="2" />
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon v-bind="attrs" v-on="on" @click="GetVer(item)">
                            <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                      <br />
                      <v-tooltip
                        bottom
                        v-if="validarAcciones(acciones.INVENTARIO_COMPRA_MODIFICAR) && (item.estado != 34 && item.estado != 35)"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            :disabled="item.estado == 35"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="GetEditar(item)"
                          >
                            <v-icon color="info">{{ icons.mdiPencil }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="validarAcciones(acciones.INVENTARIO_COMPRA_ANULAR) && (item.estado != 34 && item.estado != 35)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            :disabled="item.estado == 35"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="GetAnular(item)"
                          >
                            <v-icon color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Anular</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="validarAcciones(acciones.INVENTARIO_COMPRA_VALIDAR) && (item.estado != 34 && item.estado != 35)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            :disabled="item.estado == 34"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="GetValidar(item)"
                          >
                            <v-icon color="success">{{ icons.mdiAccountCheck }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Validar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody></tbody>
              </template>
            </v-simple-table>
            <div class="mt-6">
              <v-divider class="mt-6 my-2"></v-divider>
              <v-row>
                <v-col cols="auto">
                  <v-select
                    @change="cargar()"
                    outlined
                    dense
                    v-model="Limit"
                    :items="[5, 10, 50]"
                    label="Limite"
                  ></v-select>
                </v-col>

                <v-col class="text-center">
                  <v-pagination
                    :disabled="cargandoDatos"
                    v-model="Pagina"
                    :length="Paginas"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
                <v-col cols="auto">
                  <span>Total Registros: {{ TotalRow }}</span>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <ModalViewCompra
            @GetEditar="GetEditar"
            @GetAnular="GetAnular"
            @GetValidar="GetValidar"
            ref="ModalViewCompraRef"
          ></ModalViewCompra>
        </v-card>

        <v-card-text>
          <ReportesView class="mb-6" :p_id_categoria="2" />
        </v-card-text>
      </div>
    </v-sheet>
  </div>
</template>
  
<script>
import CompraServices from '@/api/servicios/inventario/CompraServices'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import ModalViewCompra from './ModalViewCompra.vue'
import BtnPdfCompra from './BtnPdfCompra.vue'
import { acciones } from '@/modulos'
import ReportesView from '@/views/sistema/reportes/ReportesView.vue'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocioValor.vue'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiContentSave,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import Vue from 'vue'
export default {
  components: {
    SelectAlmacen,
    BuscarEmpleado,
    SelectEstado,
    DatePicker,
    BuscarProveedor,
    ModalViewCompra,
    BtnPdfCompra,
    ReportesView,
    SelectGrupoNegocio,
    SelectPuesto,
    StatisticsCardVertical,
  },
  watch: {
    '$store.state.puestoSelect.id_puesto': function (news, olds) {
      if (news != olds) {
        this.cargarInicio()
      }
    },
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const show = ref(false)
    const ModalViewCompraRef = ref(null)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const filtroInit = {
      id_empleado: null,
      id_almacen: null,
      estado: null,
      id_puesto: null,
      fecha_factura: null,
      fecha_creacion: null,
      id_proveedor: null,
      valor: '',
      id_grupo_negocio: 1,
    }

    const limpiarFiltros = () => {
      filtro.value = JSON.parse(JSON.stringify(filtroInit))
      cargar()
    }

    const anulando = ref(false)
    const colorEstatus = item => {
      switch (item) {
        case 33:
          return 'info'
          break
        case 34:
          return 'success'
          break
        case 35:
          return 'error'
          break
        case 29:
          return 'secondary'
          break
        default:
          return 'default'
          break
      }
    }
    const colorEstatusPago = item => {
      switch (item) {
        case 64:
          return 'warning'
          break
        case 65:
          return 'success'
          break
        case 63:
          return 'error'
          break
        default:
          return 'default'
          break
      }
    }
    const GetdatosProveedor = datos => {
      if (datos) {
        filtro.value.id_proveedor = datos.id
      } else {
        filtro.value.id_proveedor = null
      }
    }

    const filtrarPorEstado = estado => {
      if (filtro.value.estado == estado) {
        filtro.value.estado = null
      } else {
        filtro.value.estado = estado
      }

      cargar()
    }

    const GetValidar = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html:
            '<h3>¿Seguro que quiere validar la compra? </h3>' +
            `<p>${item.numero_documento ?? item.id}</p>
          <p> La compra quedara cerrada y no podra modificar. </p>
          `,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return CompraServices.validarNotaCredito({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == 'FACTURA VALIDADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }

    const GetAnular = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html:
            '<h3>¿Seguro que quiere anular la Nota de credito? </h3>' + `<p>${item.numero_documento ?? item.id}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return CompraServices.anularNotaCredito({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == 'ANULADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }
    const GetdatosEmpleado = datos => {
      if (datos) {
        filtro.value.id_empleado = datos.id
      } else {
        filtro.value.id_empleado = null
      }
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetVer = item => {
      ModalViewCompraRef.value.abrir(item.id)
    }
    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const metadata = ref({ por_recibir: 0, por_validar: 0, por_pagar: 0, total_ult_compras: 0 })
    const cargar = () => {
      try {
        cargandoDatos.value = true
        CompraServices.productoNotaCreditoListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
              metadata.value = response.data.datos.metadata
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiContentSave,
        mdiChevronUp,
        mdiChevronDown,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Numero', value: 'numero_documento' },
        { text: 'Fechas', value: 'fecha_factura' },
        { text: 'Proveedor', value: 'proveedor.nombre' },
        { text: 'Empleado', value: 'empleado_json.nombre' },
        { text: 'Total', value: 'total' },
        { text: 'Estado', value: 'estado_json.descripcion' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      colorEstatus,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetdatosEmpleado,
      GetdatosProveedor,
      ModalViewCompraRef,
      GetVer,
      GetAnular,
      anulando,
      validarAcciones,
      acciones,
      GetValidar,
      colorEstatusPago,
      show,
      metadata,
      filtrarPorEstado,
      limpiarFiltros,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  